import React, { useState } from 'react';

const StepOneForm = props => {
  const [selected, setSelected] = useState("false");
  return (
    <section className="subscription-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="subscription-col">
              <h4>Annual subscription</h4>
              <h2>
                £70 <span>each month</span>
              </h2>
              <p>
                You pay £840 <span>for an annual subscription</span>
              </p>
              <div className="subscription-button">
                <input type="radio" name="sub_btn" id="sub_btn1" onChange={(e) => { setSelected(e.target.value) }} value={"true"} />
                <label htmlFor="sub_btn1" className="btn">
                  Select
                  </label>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="subscription-col">
              <h4>6 month subscription</h4>
              <h2>
                £75 <span>each month</span>
              </h2>
              <p>
                You pay £450 <span>for an annual subscription</span>
              </p>
              <div className="subscription-button">
                <input type="radio" name="sub_btn" id="sub_btn2" onChange={(e) => { setSelected(e.target.value) }} value={"true"} />
                <label htmlFor="sub_btn2" className="btn">
                  Select
                    </label>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="subscription-col">
              <h4>1 month subscription</h4>
              <h2>
                £80 <span>each month</span>
              </h2>
              <p>
                You pay £80 <span>for a 1 month subscription</span>
              </p>
              <div className="subscription-button">
                <input type="radio" name="sub_btn" id="sub_btn3" onChange={(e) => { setSelected(e.target.value) }} value={"true"} />
                <label htmlFor="sub_btn3" className="btn">
                  Select
                    </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="next-step">
              <button className={`btn arrow-btn ${selected === 'false' ? 'disable-btn' : ''}`} onClick={props.handleNext}>NEXT</button>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default StepOneForm;