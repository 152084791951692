import React from 'react';
const Modal = (props) => {

  return (
    <div className="popup-msg-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="popup-msg">
              {
                props.children
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;