import React, { Component } from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import step_1_img from "../../assets/images/step-1.svg";
import step_2_img from "../../assets/images/step-2.svg";
import step_3_img from "../../assets/images/step-3.svg";
import StepOneForm from "./step-one-from";
import StepTwoForm from "./step-two-form";
import StepThreeForm from "./step-three-form";
import StepFourForm from "./step-four-form";
import OrderCompleteModal from './order-complete-modal';
import Modal from './modal';

import check_ic from '../../assets/images/check-ic.svg';

import { withRouter } from 'react-router'

class Orders extends Component {

  state = {
    stepCount: 1,
    isOrderComplete: false,
    notFromUnitedForm: {},
    noneTraningForm: {},
    orderCompleteForm: {},
    loading: true,
    isModalOpen: false,
    responseSuccess: false,
    stepThreeFormInitialValues : {
      unitedKingdom: "",
      notFromUnitedFormFirstName: "",
      notFromUnitedFormLastName: "",
      notFromUnitedFormEmail: "",
      notFromUnitedFormConfirmEmail: "",
      notFromUnitedFormCountry:"",

      training: "",
      noneTraningFormFirstName: "",
      noneTraningFormLastName: "",
      noneTraningFormEmail: "",
      noneTraningFormConfirmEmail: "",

      camAPSUserExist: "",
      camAPSUserFormFirstName: "",
      camAPSUserFormLastName: "",
      camAPSUserFormEmail: "",
      camAPSUserFormConfirmEmail: "",

      referrence: "",
      serial1: "",
      serial2: "",
      serial3: "",
      confirmSerial1: "",
      confirmSerial2: "",
      confirmSerial3: "",

      exemptVat: "false",
      exemptVatFormFirstName: "",
      exemptVatFormLastName: "",
      exemptVatFormAddress1: "",
      exemptVatFormAddress2: "",
      exemptVatFormAddress3: "",
      exemptVatFormPostalCode: "",
      sickness: "",
      signature: "",
      day: "",
      month: "",
      year: ""
  }
  };
  render() {
    return (
      <div>
        <Header />
        {
          this.state.stepCount === 1 && this.subsCriptionHeader()
        }
          <section className="step-gradient-overlay">
        {
          this.state.stepCount !== 1 && (
            <section className="step-chart-sec">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="step-chart-wrapper">
                      <div className={`step-col ${this.state.stepCount > 1 ? 'step-done' : ''} `}>
                        <h4 className={`${this.state.stepCount === 1 ? 'font-weight-bold': ''}`}>Stage 1</h4>
                        <p>Subscription option</p>{" "}
                        <span className="circle-step"></span>
                        <span className="circle-step-line"></span>
                      </div>
                      <div className={`step-col ${this.state.stepCount > 2 ? 'step-done' : this.state.stepCount > 1 ? '' : 'step-disable'} `}>
                        <h4 className={`${this.state.stepCount === 2 ? 'font-weight-bold': ''}`}>Stage 2</h4>
                        <p>Equipment</p> <span className="circle-step"></span>
                        <span className="circle-step-line"></span>
                      </div>
                      <div className={`step-col ${this.state.stepCount > 3 ? 'step-done' : this.state.stepCount > 2 ? '' : 'step-disable'} `}>
                        <h4 className={`${this.state.stepCount === 3 ? 'font-weight-bold': ''}`}>Stage 3</h4>
                        <p>Personal Information</p>{" "}
                        <span className="circle-step"></span>
                        <span className="circle-step-line"></span>
                      </div>
                      <div className={`step-col ${this.state.stepCount > 4 ? 'step-done' : this.state.stepCount > 3 ? '' : 'step-disable'} `}>
                        <h4 className={`${this.state.stepCount === 4 ? 'font-weight-bold': ''}`}>Stage 4</h4>
                        <p>Order confirmation</p>{" "}
                        <span className="circle-step"></span>
                        <span className="circle-step-line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        }
        {
          this.state.stepCount === 1 && (
            <StepOneForm
              handleNext={this.handleNext}
              
            />
          )
        }
        {
          this.state.stepCount === 2 && (
            <StepTwoForm
              handleNext={this.handleNext}
              handlePrevious={this.handlePrevious}
            />
          )
        }

        {
          this.state.stepCount === 3 && (
            <StepThreeForm handlePrevious={this.handlePrevious} handleNext={this.handleNext} handleSubmit={this.handleSubmit} initialValues={this.state.stepThreeFormInitialValues} />
          )
        }

        {
          this.state.stepCount === 4 && (
            <StepFourForm handlePrevious={this.handlePrevious} handleComplete={this.handleComplete} orderSummary={this.state.orderCompleteForm} />
          )
        }
        {
          this.state.isOrderComplete && <OrderCompleteModal closeModal={() => {
            this.setState({ isOrderComplete: false }, () => {
              this.props.history.push('/home')
            })
          }} load={this.state.loading}  response={this.state.responseSuccess}/>
        }
        {
          this.state.isModalOpen && <Modal>
            <h5>Thank you for submitting your details</h5>
            <img src={check_ic} alt="icon" />
             <p> We will be in touch soon</p>
            <button className="btn" type={"button"} onClick={() => this.setState({isModalOpen: false}, () => {
              this.props.history.push('/home')
            })}>ok</button>
          </Modal>
        }
          </section>
        <Footer />
      </div>
    );
  }


  /**
   * subsCriptionHeader header method is used to return subscription div when step count is initial  or 1
   */
  subsCriptionHeader = () => {
    return (
      <>
        <section className="inner-banner order-page-banner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10">
                <h1>
                  Interested in using <span>CamAPS FX?</span>
                </h1>
                <p>
                  You will need to answer a few questions before submitting your
                  order.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="blue-step-box">
          <div className="container">
            <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <div className="step-box-heading">
                <h2>Our subscription process</h2>
              </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="box-content">
                  <div className="box-image">
                    <img src={step_1_img} alt="icon" />
                  </div>
                  <h4>Choose your subscription package</h4>
               
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="box-content">
                  <div className="box-image">
                    <img src={step_2_img} alt="icon" />
                  </div>
                  <h4>Answer a few questions about yourself</h4>
                 
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="box-content">
                  <div className="box-image">
                    <img src={step_3_img} alt="icon" />
                  </div>
                  <h4>Receive training from your clinician</h4>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  /**
   * handlePrevious method is used to set count of previous step
   */
  handlePrevious = () => {
    if (this.state.stepCount > 1) {
      this.setState({
        stepCount: this.state.stepCount - 1
      });
    }
  };

  /**
   * handleNext method is used to set next count 
   */
  handleNext = () => {
    if (this.state.stepCount < 5) {
      window.scrollTo(0, 0);
      this.setState({ stepCount: this.state.stepCount + 1 });
    }
  };


  /**
   * handleSubmit method is used to handle submit button and next of third form
   * it will check that order complete details are filled or not if then submit will fire else form will go to next
   */
  handleSubmit = (value, tag) => {
    const obj = {};
    Object.keys(value).forEach((key) => {
      if (key.includes(tag)) {
        obj[key.substring(tag.length).toLocaleLowerCase()] = value[key];
      }
    })

    /**
     * when user select no from united kingdom in third step and he fills form and submit than state will set with following filled details 
     */
    if (tag === 'notFromUnitedForm') {
      this.setState({
        notFromUnitedForm: obj,
        isModalOpen: true,
      }, () => {
        // console.log(this.state.notFromUnitedForm, 'not from united kingdom');
        // this.props.history.push('/home')
      });

      /**
       * when user selects no training from any clinic in third step than form will be display and on submit state will be set
       */
    } else if (tag === 'noneTraningForm') {
      this.setState({
        noneTraningForm: obj,
        isModalOpen: true
      })

      /**
       * else when user complete third form completely and press next than order summary will be set and display to 4th form
       */
    } else {
      console.log(value, 'asass')
      const completeObj = {}
      let keys = ["training", "camAPSUserFormFirstName", "camAPSUserFormLastName", "camAPSUserFormEmail", "camAPSUserFormConfirmEmail", "referrence", "serial1", "serial2", "serial3", "confirmSerial1", "confirmSerial2", "confirmSerial3"]
      keys.forEach(k => {
        completeObj[k] = value[k]
      });
      completeObj['serialNumber'] = completeObj['serial1'] + completeObj['serial2'] + completeObj['serial3'];
      // console.log('====================================');
      // console.log(completeObj);
      // console.log('====================================');
      this.setState({
        stepThreeFormInitialValues: value,
        orderCompleteForm: completeObj
      }, () => {
        this.handleNext();
      })
    }
  };

  /**
   * when user complete order complete than state is set and modal will open
   */
  handleComplete = (values) => {
    // console.log('====================================');
    // console.log(this.state.isOrderComplete);
    // console.log('====================================');
    // console.log(values.discountCode, 'desd')
    this.setState({
      isOrderComplete: true,
      responseSuccess: values.discountCode === '' ? true: false
    }, () => {
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 1800)
    })
  }
}


export default withRouter(Orders)