import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import mobile_logo from '../assets/images/mobile-logo.svg';
import logo from '../assets/images/logo.svg';


export class GerHeader extends Component {
    container = React.createRef();
    constructor() {
        super();
        this.state = {
            clickedOutside: false,
            faq: false,
            support: false,
            history: false,
            open: false,
        }
    }
    componentDidMount() {

        document.addEventListener("mousedown", this.handleClickOutside);
        document.body.classList.remove("open-language");
        setTimeout(function () {

            document.body.classList.remove('open-toggle');
        }, 10)

        var el = document.querySelector('.menu-icon');
        el.onclick = () => {
            document.body.classList.toggle('open-toggle');
        }
        // Get the Link Class//
        document.querySelectorAll(".site-navigation nav ul li a").forEach((item, index) => {
            //console.log(item.textContent);
            item.parentNode.classList.remove("active");
            if (item.getAttribute("href") === window.location.pathname) {
                item.parentNode.classList.add("active");
            }
        })

    }
    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
                open: false,

            });
            document.body.classList.remove('open-language');
        }
    };
    handleButtonClick = () => {
        this.setState(state => {
            return {
                open: !state.open,
            };
        });
        document.body.classList.toggle('open-language');
    };
    // toogling = (e) => {

    // }
    removeToogling = (e) => {
        setTimeout(function () {

            document.body.classList.remove('open-toggle');
        }, 10)
    }
    handleClickInside = () => {
        this.setState({ clickedOutside: false });
    };
    render() {
        return (
            <div>
                <header className="header-main">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 header-wrap">
                                <div className="logo">
                                    <Link to="/ger-home">
                                        <img src={logo} className="App-logo" alt="logo" />
                                        <img src={mobile_logo} className="mobile-logo" alt="logo" />
                                    </Link>
                                </div>
                                <div className="header-navigation">
                                    <div className="menu-icon" >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="navigation-wrap">
                                        <div className="site-navigation">
                                            <nav>
                                                <ul>
                                                    <li onClick={(e) => this.removeToogling(e)}><Link to="/ger-faq">FAQs</Link></li>
                                                    <li onClick={(e) => this.removeToogling(e)}><Link to="/ger-support">Support</Link></li>
                                                    <li onClick={(e) => this.removeToogling(e)}><Link to="/ger-history">History</Link></li>
                                                    <li onClick={(e) => this.removeToogling(e)}><Link to="/ger-orders">Orders</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="lang-dropdown" ref={this.container}>
                                            <div className="lang-label" >
                                                <input type="checkbox" id="menu-toggle" onClick={(e) => this.handleButtonClick(e)} />
                                                <label htmlFor="menu-toggle"  >German</label>
                                                {this.state.open && (
                                                    <div className="lang-list">
                                                        <ul>
                                                            <li>
                                                                <a href="#" title="French">French</a>
                                                            </li>
                                                            <li>
                                                                {/* <a href="#">German</a> */}
                                                                <Link to="/home" >English</Link>

                                                            </li>
                                                            <li>
                                                                <a href="#" title="Spanish">Spanish</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" title="Arabic">Arabic</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}


