import React from 'react';
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, FastField } from "formik";
import {
  errorMessage,
  RenderRadio
} from "../inputTypes";
const StepOneForm = (props) => {
  return (
    <Formik
      initialValues={{
        question1: false,
        question2: false,
        question3: false
      }}
      onSubmit={props.handleSubmit}
      isInitialValid={false}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={stepOneValidationSchema}
    >{({ handleSubmit, values, isValid, setFieldValue, setFieldTouched }) => (
     
        <section className="step-chart-content">
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="step-content-row">
                    <div className="step-content-left">
                      <p>Do you have a Dana Diabecare RS Pump?</p>
                      <div className="div-content">
                        <ErrorMessage name="question1" component={errorMessage} />
                      </div>
                    </div>
                    <div className="step-content-right">
                      <div className="custom-radio">
                        <input type="radio" name="question1" id="stage_btn1" value={"true"} onChange={(e) => {
                          const selectedValue = e.target.value;
                          setTimeout(async () => {
                            await setFieldTouched("question1", true);
                            await setFieldValue("question1", selectedValue)
                          })
                        }} />
                        <label htmlFor="stage_btn1">Yes</label>
                      </div>
                      <div className="custom-radio">
                        <input type="radio" name="question1" id="stage_btn2" value={"false"} onChange={(e) => {
                          const selectedValue = e.target.value;
                          setTimeout(async () => {
                            await setFieldTouched("question1", true);
                            await setFieldValue("question1", selectedValue)
                          })
                        }} />
                        <label htmlFor="stage_btn2" className="red-border">
                          No
                      </label>
                      </div>
                    </div>
                  </div>
                  <div className="step-content-row">
                    <div className="step-content-left">
                      <p>Do you have a Dexcom G6 Glucose Sensor?</p>
                      <div className="div-content">
                        <ErrorMessage name="question2" component={errorMessage} />
                      </div>
                    </div>
                    <div className="step-content-right">
                      <div className="custom-radio">
                        <input type="radio" name="question2" id="stage_btn3" value="true" onChange={(e) => {
                          const selectedValue = e.target.value;
                          setTimeout(async () => {
                            await setFieldTouched("question2", true);
                            await setFieldValue("question2", selectedValue)
                          })

                        }} />
                        <label htmlFor="stage_btn3">Yes</label>
                      </div>
                      <div className="custom-radio">
                        <input type="radio" name="question2" id="stage_btn4" value="false" onChange={(e) => {
                          const selectedValue = e.target.value;
                          setTimeout(async () => {
                            await setFieldTouched("question2", true);
                            await setFieldValue("question2", selectedValue)
                          })
                        }} />
                        <label htmlFor="stage_btn4" className="red-border">
                          No
                      </label>
                      </div>
                    </div>
                  </div>
                  <div className="step-content-row">
                    <div className="step-content-left">
                      <p>Do you have a compatible <u>Android phone?</u></p>
                      <div className="div-content">
                        <ErrorMessage name="question3" component={errorMessage} />
                      </div>
                    </div>
                    <div className="step-content-right">
                      <div className="custom-radio">
                        <input type="radio" name="question3" id="stage_btn5" value="true" onChange={(e) => {
                          const selectedValue = e.target.value;
                          setTimeout(async () => {
                            await setFieldTouched("question3", true);
                            await setFieldValue("question3", selectedValue)
                          })

                        }} />
                        <label htmlFor="stage_btn5">Yes</label>
                      </div>
                      <div className="custom-radio">
                        <input type="radio" name="question3" id="stage_btn6" value="false" onChange={(e) => {
                          const selectedValue = e.target.value;
                          setTimeout(async () => {
                            await setFieldTouched("question3", true);
                            await setFieldValue("question3", selectedValue)
                          })
                        }} />
                        <label htmlFor="stage_btn6" className="red-border">
                          No
                      </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="next-step">
                    <button className="btn arrow-btn prev-btn" onClick={props.handlePrevious}>PREVIOUS</button>
                    <button className={`btn arrow-btn ${!isValid ? 'disable-btn' : ''}`} type="button" onClick={props.handleNext} disabled={!isValid}>NEXT</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
    )}
    </Formik>
  );
};


const stepOneValidationSchema = Yup.object().shape({
  question1: Yup.boolean()
    .required()
    .oneOf([true], " Unfortunately without the correct glucose sensor the CamAPS FX system will not be compatible and may provide false information. Please consult your local diabetes clinician about the Dexcom G6 glucose sensor."),
  question2: Yup.boolean()
    .required()
    .oneOf([true], "Please contact your clinical team and discuss your options or contact <u>Advanced Therapeutics UK</u> who supply Dana Diabecare RS pumps in the UK."),
  question3: Yup.boolean()
    .required()
    .oneOf([true], " Without using compatible Android phone, we are unable to guarantee reliable connection to your Dexcom G6 sensor. Consider purchasing compatible Android phone."),
});

export default StepOneForm;