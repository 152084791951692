import React from 'react';
import load_ic from '../../assets/images/load-ic.svg';
import check_ic from '../../assets/images/check-ic.svg';
import error_ic from '../../assets/images/red-close.svg';
const completeConfirmation = (props) => {

  return (
    <div className="popup-msg-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className={`popup-msg ${props.response? 'popup-msg' : props.load ? 'popup-msg' : 'popup-msg-error'}`} >
              {
                props.load && (
              <>
                    <h5>Your order is being processed</h5>
                    <img src={load_ic} alt="icon" className="load-ic"  />
                  </>)
              }

              {
                !props.load && (
                  <>
                    <h5>{props.response ? 'Thank you, your order was successfull' : 'Processing error'} </h5>
                    <img src={props.response ? check_ic : error_ic} alt="icon" />
                    <p>{props.response ? 'You should receive a confirmation email shortly': 'An error occurred while processing your order, please check your internt connection.'}  </p>
                    <button className="btn" onClick={(e) => { e.preventDefault(); props.closeModal() }}>ok</button>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default completeConfirmation;