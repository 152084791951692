import React from 'react';
import * as Yup from "yup";
import { Formik, Field } from "formik";
import captcha_img from '../../assets/images/captcha.png';

const StepFourForm = props => {
  return (
    <Formik
      initialValues={{ discountCode: "", radio1: "",radio2:""}}
      onSubmit={() => console.log()}
      validateOnChange={true}
      isInitialValid={false}
      validateOnBlur={true}
      validationSchema={orderSummaryFormValidation}
    >
      {({ values, setFieldTouched, setFieldValue, isValid }) => (
        <form>
          <section className="order-confirmation min-height-200">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="order-info-left">
                    <p>Paragraph explaining next steps:</p>
                    <p>You can download the app from Amazon Appstore but you will not able to use until you are trained</p>
                    <p>You will need to contact certified trainer to be trained - following training you will be able to use the app</p>
                    <p>Payments will be by Direct Debit on acceptance of your order and after you have been trained on CamAPS FX app</p>
                    <div className="term-agree">
                      <p>I agree to the following:</p>
                      <p>
                        <Field type="checkbox" name="radio1" id="terms-condition" value="true" onChange={(e) => {
                          if(e.target.checked) {
                            const selectedValue = e.target.value;
                            setTimeout(async () => {
                              await setFieldTouched("radio1", false);
                              await setFieldValue(
                                "radio1",
                                selectedValue
                              );
                            });
                          } else {
                             setFieldValue(
                              "radio1",
                              ""
                            );
                          }
                        }}
                        checked={values.radio1 === "true"}
                        />
                        <label htmlFor="terms-condition"><u>Terms and conditions</u>
                        </label>
                      </p>
                      <p>
                        <Field type="checkbox" name="radio2" id="privacy-policy" value="true" onChange={(e) => {
                          if(e.target.checked) {
                            const selectedValue = e.target.value;
                            setTimeout(async () => {
                              await setFieldTouched("radio2", false);
                              await setFieldValue(
                                "radio2",
                                selectedValue
                              );
                            });
                          } else {
                             setFieldValue(
                              "radio2",
                              ""
                            );
                          }
                        }}
                        checked={values.radio2 === "true"}
                        />
                        <label htmlFor="privacy-policy"><u>Privacy Policy</u>
                        </label>
                      </p>
                    </div>
                    <div className="captcha-col">
                      <img src={captcha_img} alt="icon" />
                    </div>
                    <div className="discount-code">
                      <Field type="text" placeholder="Discount Code(optional)" name="discountCode" value={values.discountCode} />

                      <input type="button" className="btn" value="APPLY CODE" disabled={!isValid} />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="order-info-right">
                    <p>Order Summary</p>
                    <p>{props.orderSummary.camAPSUserFormFirstName || 'Not Available'}</p>
                    <p>{props.orderSummary.camAPSUserFormLastName || 'Not Available'}</p>
                    <p>{props.orderSummary.camAPSUserFormEmail || 'Not Available'}</p>
                    <p>{props.orderSummary.training || 'Not Available'}</p>
                    <p>6 month subscription</p>
                    <p>Total (per month) <span>£75</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="next-step">
                    <button className="btn arrow-btn prev-btn" onClick={props.handlePrevious}>PREVIOUS</button>
                    {
                      // console.log(isValid)
                    }
                    <button className={`btn arrow-btn arrow-complete ${!isValid ? 'disable-btn' : ''}`} disabled={!isValid} onClick={(e) => { e.preventDefault(); props.handleComplete(values) }}>Complete</button>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </form>
      )}

    </Formik>
  )
}
const orderSummaryFormValidation = Yup.object().shape({
  discountCode: Yup.string().nullable(true),radio1: Yup.string().trim().required('Discount Code is required'),radio2: Yup.string().trim().required('Discount Code is required')
})
export default StepFourForm;